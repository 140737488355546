/* table */
.policy_documents .policy_documents_report_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.policy_documents .policy_documents_report_table, .policy_documents .policy_documents_report_table td{
    padding: .5rem .1rem;
}

.policy_documents .policy_documents_report_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.policy_documents .policy_documents_report_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(1){
    width: 2%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(2){
    width: 19%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(3){
    width: 15%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(4){
    width: 15%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(5){
    width: 15%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(6){
    width: 7%
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(7){
    width: 7%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(8){
    width: 15%;
}

.policy_documents .policy_documents_report_table thead tr td:nth-child(9){
    width: 5%;
}