/* table */
.view_claim .view_claim_policy_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.view_claim .view_claim_policy_table, .view_claim .view_claim_policy_table td{
    padding: .5rem .1rem;
}

.view_claim .view_claim_policy_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.view_claim .view_claim_policy_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(1){
    width: 2%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(2){
    width: 18%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(3){
    width: 15%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(4){
    width: 15%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(5){
    width: 15%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(6){
    width: 10%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(7){
    width: 10%;
}

.view_claim .view_claim_policy_table thead tr td:nth-child(8){
    width: 15%;
}

/* status table */
.view_claim .status_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.view_claim .status_table, .view_claim .status_table td{
    padding: .5rem .1rem;
    /* border: 1px solid black;
    border-collapse: collapse; */
}

.view_claim .status_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.view_claim .status_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.view_claim .status_table thead tr td:nth-child(1){
    width: 90%;
}

.view_claim .status_table thead tr td:nth-child(3){
    width: 10%;
}



/* table */
.view_claim .claim_documents_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.view_claim .claim_documents_table, .view_claim .claim_documents_table td{
    padding: .5rem .5rem;
}

.view_claim .claim_documents_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.view_claim .claim_documents_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.view_claim .claim_documents_table thead tr td:nth-child(1){
    width: 5%;
}

.view_claim .claim_documents_table thead tr td:nth-child(2){
    width: 60%;
}

.view_claim .claim_documents_table thead tr td:nth-child(3){
    width: 35%;
}


.view_claim .claim_documents_table tbody tr td:nth-child(2):hover{
    text-decoration: underline;
}

.view_claim .claim_documents_table tbody tr td input[type="checkbox"]{
    height: .8rem;
}