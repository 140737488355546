.f_1 {
    font-size: .7rem;
}

.action_memo_input {
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
}

.input_error::placeholder{
    color: var(--col7) !important;
}