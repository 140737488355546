.receipt_date_container {
    display: flex;
    width: inherit;
}

.receipt_date_view {
    width: 90%;
}

.receipt_date_selector {
    width: 10%;
}

.receipt_date_selector i {
    position: absolute;
}

.receipt_date_selector input {
    position: relative;
    z-index: 1;
    width: 1rem;
}

/* allocation */
.category_header.receipt_allocation_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category_header.receipt_allocation_section i{
    font-size: 1.1rem;
}

/* search */
.receipt_allocation_rightNav{
    display: grid;
    grid-template-columns: repeat(18,1fr);
    grid-template-rows: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    width: 100%;
    
}

.receipt_allocation_rightNav .receipt_allocation_minimise {
    grid-column: 1/16;
}

.receipt_allocation_rightNav .receipt_allocation_search {
    grid-column: 16/-1;
    padding: .7rem .5rem;
    color: white;
}

/* search input */
.receipt_allocation_search .search_input input{
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col1);
    background-color: transparent;
    padding: .2rem .5rem;
    font-size: .9rem;
    color: var(--col1);
}

.receipt_allocation_search .search_input input:focus{
    outline: none;
    border-bottom: 0.1px solid var(--col1);
}

.receipt_allocation_search .search_input p{
    font-size: .9rem;
}

/* search results */
.receipt_allocation_search .search_results .result{
    padding: .5rem;
    border: 0.1px solid var(--col4);
    border-radius: .3rem;
    cursor: pointer;
    font-size: .7rem;
}

/* allocation table */
.receipt_allocation_section .receipt_allocation_table{
    width: 100%;
    font-size: .9rem;
}

.receipt_allocation_table, .receipt_allocation_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.receipt_allocation_section .receipt_allocation_table thead td:nth-child(1){
    width: 20%;
}

.receipt_allocation_section .receipt_allocation_table thead td:nth-child(2){
    width: 20%;
}

.receipt_allocation_section .receipt_allocation_table thead td:nth-child(3){
    width: 15%;
}

.receipt_allocation_section .receipt_allocation_table thead td:nth-child(4){
    width: 15%;
}

.receipt_allocation_section .receipt_allocation_table thead td:nth-child(5){
    width: 15%;
}

.receipt_allocation_section .receipt_allocation_table thead td:nth-child(6){
    width: 15%;
}
