/* table */
.view_receipt .view_receipt_table {
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.view_receipt .view_receipt_table,
.view_receipt .view_receipt_table td {
    padding: .5rem .1rem;
}

.view_receipt .view_receipt_table tbody tr:nth-child(even) {
    background-color: #ced0ee;
}

.view_receipt .view_receipt_table thead tr:nth-child(odd) {
    background-color: #cacdf1;
}

.view_receipt .view_receipt_table thead tr td:nth-child(1) {
    width: 2%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(2) {
    width: 19%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(3) {
    width: 17%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(4) {
    width: 17%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(5) {
    width: 17%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(6) {
    width: 7%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(7) {
    width: 7%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(8) {
    width: 7%;
}

.view_receipt .view_receipt_table thead tr td:nth-child(9) {
    width: 7%;
}

/* receipt doc */
.view_receipt .view_receipt_receipt_document {
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.view_receipt .view_receipt_receipt_document,
.view_receipt .view_receipt_receipt_document td {
    padding: .5rem .1rem;
}

.view_receipt .view_receipt_receipt_document tbody tr:nth-child(even) {
    background-color: #ced0ee;
}

.view_receipt .view_receipt_receipt_document thead tr:nth-child(odd) {
    background-color: #cacdf1;
}

.view_receipt .view_receipt_receipt_document thead tr td:nth-child(1) {
    width: 2%;
}

.view_receipt .view_receipt_receipt_document thead tr td:nth-child(2) {
    width: 98%;
}

.view_receipt .view_receipt_receipt_document tbody tr td:nth-child(2):hover {
    text-decoration: underline;
}

/* simia receipt */
.view_receipt .simia_receipt_template_content {
    width: 100%;
    font-size: .6rem;
}

/* section 1 */
.view_receipt .simia_receipt_template_table_section_one{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* section 2 */
.view_receipt .simia_receipt_template_table_section_two {
    width: 100%;
}

.view_receipt .simia_receipt_template_table_section_two,
.view_receipt .simia_receipt_template_table_section_two td {
    padding: .5rem .3rem;
}

.view_receipt .simia_receipt_template_table_section_two thead tr td {
    vertical-align: top;
}

.view_receipt .simia_receipt_template_table_section_two thead tr td:nth-child(1) {
    width: 35%;
}

.view_receipt .simia_receipt_template_table_section_two thead tr td:nth-child(2) {
    width: 40%;
}

.view_receipt .simia_receipt_template_table_section_two thead tr td:nth-child(3) {
    width: 25%;
}

/* section 3 */
.view_receipt .simia_receipt_template_table_section_three {
    width: 100%;
}

.view_receipt .simia_receipt_template_table_section_three,
.view_receipt .simia_receipt_template_table_section_three td {
    padding: .5rem .3rem;
}

.view_receipt .simia_receipt_template_table_section_three tbody tr:nth-child(even) {
    background-color: #ced0ee;
}

.view_receipt .simia_receipt_template_table_section_three thead tr:nth-child(odd) {
    background-color: #cacdf1;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(1) {
    width: 22%;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(2) {
    width: 22%;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(3) {
    width: 22%;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(4) {
    width: 8%;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(5) {
    width: 8%;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(6) {
    width: 8%;
}

.view_receipt .simia_receipt_template_table_section_three thead tr td:nth-child(7) {
    width: 10%;
}

/* section 4 */
.view_receipt .simia_receipt_template_table_section_four {
    width: 40%;
    margin-left: auto;
}

.view_receipt .simia_receipt_template_table_section_four,
.view_receipt .simia_receipt_template_table_section_four td {
    padding: .7rem .5rem;
}

.view_receipt .simia_receipt_template_table_section_four tbody tr:nth-child(even) {
    background-color: #ced0ee;
}

.view_receipt .simia_receipt_template_table_section_four thead tr:nth-child(odd) {
    background-color: #cacdf1;
}

.view_receipt .simia_receipt_template_table_section_four thead tr td:nth-child(1) {
    width: 50%;
}

.view_receipt .simia_receipt_template_table_section_four thead tr td:nth-child(2) {
    width: 50%;
}

.view_receipt .simia_receipt_template_table_section_four thead tr td:nth-child(2) {
    text-align: right;
}


