.renewal_schedule_template{
    width: 100%;
}

.renewal_schedule_template_header_text{
    text-align: center;
    font-size: .7rem;
    font-weight: bold;
}

.renewal_schedule_template_table{
    width: 100%;
    
    
    font-size: .5rem;
}

.renewal_schedule_template_table td{
    border: .5px solid black;
    padding: .2rem;
}

.renewal_schedule_template_table thead {
    visibility: collapse;
}

.renewal_schedule_template_table thead tr td:nth-child(1) {
    width: 17%;
}

.renewal_schedule_template_table thead tr td:nth-child(2) {
    width: 16%;
}

.renewal_schedule_template_table thead tr td:nth-child(3) {
    width: 17%;
}

.renewal_schedule_template_table thead tr td:nth-child(4) {
    width: 17%;
}

.renewal_schedule_template_table thead tr td:nth-child(5) {
    width: 17%;
}

.renewal_schedule_template_table thead tr td:nth-child(6) {
    width: 16%;
}

.renewal_schedule_template_table_header{
    font-weight: bold;
    font-size: .7rem;
    text-align: center;
}

.renewal_schedule_template_description_table{
    width: 100%;
}

.renewal_schedule_template_description_table td, .renewal_schedule_template_description_table{
    border: none !important;
}

.renewal_schedule_template_description_table thead tr td:nth-child(1) {
    width: 80%;
}

.renewal_schedule_template_description_table thead tr td:nth-child(2) {
    width: 20%;
}




.renewal_schedule_template_table_info{
    width: 100%;
    font-size: .6rem;
}

.renewal_schedule_template_table_info td{
    border: 1px solid black;
    padding: .2rem;
    vertical-align: top;
}

.renewal_schedule_template_table_info thead {
    visibility: collapse;
}

.renewal_schedule_template_table_info thead tr td:nth-child(1) {
    width: 33.3% !important;
}

.renewal_schedule_template_table_info thead tr td:nth-child(2) {
    width: 33.3% !important;
}

.renewal_schedule_template_table_info thead tr td:nth-child(3) {
    width: 33.3% !important;
}




.renewal_schedule_template_logo_container {
    height: 8rem;
    display: flex;
    justify-content: end;
    align-items: center;
}

.renewal_schedule_template_logo_container img {
    height: 8rem;
    width: 8rem;
}



@media print {
    .renewal_schedule_template_table{
        page-break-inside:auto
    }

    .renewal_schedule_template_table tbody tr {
        page-break-inside:avoid; 
        page-break-after:auto
    }
  }
  