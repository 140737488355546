@media print {

    .overview_final_page_break {
        break-after: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

    #myGrid {
        width: 100%;
    }
}