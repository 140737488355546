.CP_overview {
    width: 100%;
    max-width: 100%;
    border: 1px solid red;
    font-size: .6rem;
}

.CP_overview,
.CP_overview tr td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .2rem .2rem;
    cursor: pointer;
}

.CP_overview thead tr td:nth-child(1) {
    width: 2%;
}

.CP_overview thead tr td:nth-child(2) {
    width: 2%;
}

.CP_overview thead tr td:nth-child(3) {
    width: 24%;
}

.CP_overview thead tr td:nth-child(4) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(5) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(6) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(7) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(8) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(9) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(10) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(11) {
    width: 8%;
}

.CP_overview thead tr td:nth-child(12) {
    width: 8%;
}

.CP_overview.table_header_collapsed thead {
    visibility: collapse;
}

/* style icons */
.overview_controls i{
    font-size: .9rem;
}

.overview_controls{
    display: flex;
}

/* print */
@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .footer {
        position: fixed;
        bottom: 0;
      }
}

@page {
    size: A4;
    margin: 20mm;
  }

  @media print {
    table{
        width: 100%;
        border: 1px solid black;
        font-size: .6rem;
    }
    
    table, th, td{
        border: 1px solid black;
        border-collapse: collapse;
        padding: .5rem .1rem;
    }
    
}

/* search */
.ageing_overview .policy_search_container input{
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem .2rem 1.2rem;
    font-size: .9rem;
}

.ageing_overview .policy_search_container input:focus{
    outline: none;
    border-bottom: 0.1px solid var(--col4);
}

.ageing_overview .policy_search_container{
    display: flex;
    align-items: center;
}

.ageing_overview .policy_search_container i{
    position: absolute;
    z-index: 1;
    margin-left: auto;
}