.f_1 {
    font-size: .7rem;
}

.veh_input {
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.selected_insurance_classes {
    background-color: var(--col5);
    color: var(--col1);
    border: none !important;
    padding: 0 .4rem;
}

/* quote control print */
@media print {
    .print_quote_td{
        padding:  0 0 0 1px !important;
        border: 1px solid grey !important;
    }
    .print_quote_table{border: none !important;}
    .quotes_table_print_only {
        visibility: visible;
    }

    .quotes_template_print_only {
        display: block;
    }

    .quotes_template_print_only_img_container {
        height: 8rem;
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .quotes_template_print_only_img {
        height: 7rem;
        width: 7rem;
    }


    .quotes_table_screen_only {
        visibility: collapse;
    }
}

@media screen {
    .quotes_table_print_only {
        visibility: collapse;
    }

    .quotes_template_print_only {
        display: none;
    }

    .quotes_template_print_only_img_container {
        display: none;
    }

    .quotes_table_screen_only {
        visibility: visible;
    }
}

.quote_container .logo_container {
    height: 8rem;
    display: flex;
    justify-content: end;
    align-items: center;
}

.quote_container .logo_container img {
    height: 7rem;
    width: 7rem;
}