.f_1 {
    font-size: .6rem !important;
}

.renewal_schedule_template_logo_container {
    height: 8rem;
    display: flex;
    justify-content: end;
    align-items: center;
}

.renewal_schedule_template_logo_container img {
    height: 8rem;
    width: 8rem;
}
