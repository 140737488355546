.search_wrapper {
    width: 100%;
    position: relative;
}

.register_a_claim .search_wrapper .input_group_search {
    position: relative;
}

.register_a_claim .search_wrapper .input_group_search .input {
    width: 100%;
    padding: 1.3rem .5rem !important;
    border-radius: 2px !important;
    outline: none !important;
    color: #444444 !important;
    border: 1px solid var(--col4) !important;
    border-radius: .2rem !important;
}

.register_a_claim .search_wrapper .input_group_search .placeholder {
    position: absolute;
    top: 12px;
    left: 8px;
    font-size: 14px;
    padding: 3px 5px;
    transition: 0.3s;
    pointer-events: none;
}

 .input:focus+.placeholder,
 .input:not(:placeholder-shown)+.placeholder {
    top: -10px !important;
    background-color: var(--col4) !important;
}

.register_a_claim .search_wrapper span.search_icon {
    position: absolute;
    background: none;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    width: 23px;
    padding: 0;
}

.register_a_claim .search_wrapper .results .select_claim_policy_table {
    list-style: none;
    border-radius: 3px;
    opacity: 0;
    display: none;
    padding: 8px 12px;
    transition: all .5s linear;
}

 .show .results ul li {
    opacity: 1;
    display: block !important;
}

 .show .results {
    padding: 10px;
}

.register_a_claim .search_wrapper .results ul li:hover {
    background: #ececec
}

/* table */
.register_a_claim .select_claim_policy_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.register_a_claim .select_claim_policy_table, .register_a_claim .select_claim_policy_table td{
    /* border: 1px solid black;
    border-collapse: collapse; */
    padding: .3rem .1rem;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(1){
    width: 2%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(2){
    width: 18%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(3){
    width: 18%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(4){
    width: 18%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(5){
    width: 18%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(6){
    width: 7%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(7){
    width: 7%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(8){
    width: 7%;
}

.register_a_claim .select_claim_policy_table thead tr td:nth-child(9){
    width: 5%;
}

.register_a_claim .select_claim_policy_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.register_a_claim .select_claim_policy_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}