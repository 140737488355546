/* table */
.client_group .client_group_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.client_group .client_group_table, .client_group .client_group_table td{
    padding: .5rem .1rem;
}

.client_group .client_group_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.client_group .client_group_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.client_group .client_group_table thead tr td:nth-child(1){
    width: 2%;
}

.client_group .client_group_table thead tr td:nth-child(2){
    width: 20%;
}

.client_group .client_group_table thead tr td:nth-child(3){
    width: 58%;
}

.client_group .client_group_table thead tr td:nth-child(4){
    width: 10%;
}