.pending_list .body_section_header .client_controls{
    display: flex;
}

/* table */
.pending_list .pending_policies_table{
    width: 100%;
    font-size: .7rem;
}

.pending_list .pending_policies_table, .policy_portfolio .pending_policies_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
    cursor: pointer;
}

.pending_list .pending_policies_table thead tr td:nth-child(1){
    width: 2%;
}

.pending_list .pending_policies_table thead tr td:nth-child(2){
    width: 10%;
}

.pending_list .pending_policies_table thead tr td:nth-child(3){
    width: 8%;
}

.pending_list .pending_policies_table thead tr td:nth-child(4){
    width: 10%;
}

.pending_list .pending_policies_table thead tr td:nth-child(5){
    width: 10%;
}

.pending_list .pending_policies_table thead tr td:nth-child(6){
    width: 8%;
}

.pending_list .pending_policies_table thead tr td:nth-child(7){
    width: 8%;
}

.pending_list .pending_policies_table thead tr td:nth-child(8){
    width: 8%;
}

.pending_list .pending_policies_table thead tr td:nth-child(9){
    width: 15%;
}

.pending_list .pending_policies_table thead tr td:nth-child(10){
    width: 8%;
}

.pending_list .pending_policies_table thead tr td:nth-child(11){
    width: 8%;
}

.pending_list .pending_policies_table thead tr td:nth-child(12){
    width: 5%;
}