.veh_input {
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
    overflow: auto;
}

.companyLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
}

.companyLogo {
    align-self: flex-end;
    width: 10rem;
    padding-top: 2rem;
    padding-right: 1rem;
}

.leaveFormTitle {
    font-size: 1rem;
    font-weight: bold;
    text-decoration: underline;
    padding-top: .5rem;
    padding-bottom: .5rem;

}

.tableMain {
    width: 100%;
    /* border: 0.005px solid #000; */
    table-layout: fixed;
}

.tableBody {
    width: 100%;
    /* border: 0.005px solid #000; */
}

.tableTitle {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 20%;
    /* border: 0.005px solid #000; */
}

.tableTitle p {
    font-weight: bold;
    font-size: .9rem;
    overflow: auto;
}

.tableField {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 30%;
    font-size: .9rem;
    overflow: auto;
    /* border: 0.005px solid #000; */
}

.tableField p {
    overflow-x: auto;
    word-wrap: break-word;
}

.tableRowDepartment {
    width: 100%;
    /* border: 0.005px solid #000; */
}

.tableFieldDepartment {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    width: 30%;
    /* border: 0.005px solid #000; */
}

.tableRowReason {
    /* border: 0.005px solid #000; */
}

.tableDataReason {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    /* border: 0.005px solid #000; */
}

.periodTableHolder {
    width: 100%;
    
    /* border: 0.005px solid #000; */
    /* table-layout: fixed; */
}

.periodTableRow {
    width: 100%;
    padding-top: .6rem;
    padding-bottom: .6rem;
}

.periodTableTitle {
    font-weight: bold;
    font-size: .9rem;
    width: 25%;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    /* border: 0.005px solid #000; */
    /* table-layout: fixed; */
}

.periodTableField{
    font-size: .9rem;
    width: 25%;
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    /* border: 0.1px solid #000; */
}

.fieldsTitles {
    font-weight: bold;
    font-size: .9rem;
}

.employerSignature, .employerApproval, .employerComments {
    padding-left: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: .9rem;
}

.employerSignature p {
    padding-bottom: .45rem;
}

.checkbox_text {
    margin-right: 8px;
    font-size: .9rem;
}

.checkbox_text_two {
    margin-right: 8px;
    margin-left: 10px;
    font-size: .9rem;
}

.approvalCheckboxInput {
    padding-left: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: black;
}

.reasonTable {
    border-collapse: collapse;
    border: none;
}

.reasonCheckbox {
    border: none;
    margin-left: 10px;
}

.reasonText {
    border: none;
    width: 25%;
    margin-right: 10px;
    font-size: .9rem;
}

.otherReason {
    font-size: .9rem;
}

