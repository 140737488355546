/* Printable Client Details */
.universal {
    font-size: 1rem;
    width: 100%;
}

.logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.logoImg {
    align-content: flex-end;
    justify-self: flex-end;
    width: 10rem;
    padding-bottom: .8rem;
}

.sectionContainer {
    width: 100%;
}

.sectionTitle {
    font-size: 1.2rem;
    text-align: start;
    font-weight: bolder;
    text-decoration: underline;
}