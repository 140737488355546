/* table */
.motor_report .motor_report_table{
    width: 100%;
    font-size: .6rem;
}

.motor_report .motor_report_table, .motor_report .motor_report_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
}

.motor_report .motor_report_table thead tr td:nth-child(1){
    width: 2%;
}

.motor_report .motor_report_table thead tr td:nth-child(2){
    width: 12%;
}

.motor_report .motor_report_table thead tr td:nth-child(3){
    width: 11%;
}

.motor_report .motor_report_table thead tr td:nth-child(4){
    width: 11%;
}

.motor_report .motor_report_table thead tr td:nth-child(5){
    width: 11%;
}

.motor_report .motor_report_table thead tr td:nth-child(6){
    width: 7%;
}

.motor_report .motor_report_table thead tr td:nth-child(7){
    width: 7%;
}

.motor_report .motor_report_table thead tr td:nth-child(8){
    width: 7%;
}

.motor_report .motor_report_table thead tr td:nth-child(9){
    width: 5%;
}

.motor_report .motor_report_table thead tr td:nth-child(10){
    width: 5%;
}

.motor_report .motor_report_table thead tr td:nth-child(11){
    width: 7%;
}

.motor_report .motor_report_table thead tr td:nth-child(12){
    width: 5%;
}

.motor_report .motor_report_table thead tr td:nth-child(13){
    width: 5%;
}

.motor_report .motor_report_table thead tr td:nth-child(14){
    width: 5%;
}

/* doc icon */
.motor_report_doc_icon i{
    font-size: 1rem;
}