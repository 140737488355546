/* table */
.valuation_reports .valuation_reports_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.valuation_reports .valuation_reports_table, .valuation_reports .valuation_reports_table td{
    padding: .5rem .1rem;
}

.valuation_reports .valuation_reports_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.valuation_reports .valuation_reports_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(1){
    width: 2%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(2){
    width: 14%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(3){
    width: 13%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(4){
    width: 10%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(5){
    width: 10%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(6){
    width: 13%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(7){
    width: 10%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(8){
    max-width: 10%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(9){
    width: 10%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(10){
    width: 4%;
}

.valuation_reports .valuation_reports_table thead tr td:nth-child(11){
    width: 4%;
}