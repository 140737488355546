  
  .radio-button {
    display: none;
  }
  
  #filter {
    display: flex;
    justify-content: center;
  }
  
  .filter-label {
    display: inline-block;
    margin: 0 0 -1px;
    padding: 15px 25px;
    font-weight: 600;
    text-align: center;
    color: #bbb;
    border: 1px solid transparent;
  }
  
  main {
    clear: left;    
  }
  
  .content {  
    display: none;
    border-top: 1px solid #ddd;
  }
  
  h1 {
    font-size: 2em;
  }
  
  .date {
    padding: 5px 30px;
    font-style: italic;
  }
  
  .filter-label:hover {
    /* background-color: #505050; */
    color: #888;
    cursor: pointer;
  }
  
  /* #featured-radio:checked~#filter .featured,
  #personal-radio:checked~#filter .personal,
  #tech-radio:checked~#filter .tech {
   
    color: #555;
    border: 1px solid #ddd;
    border-top: 4px solid orange;
    border-bottom: 1px solid #fff;
  } */

 .f{
    /* background-color: green; */
    color: #555;
    border: 1px solid #ddd;
    border-top: 4px solid orange;
    border-bottom: 1px solid #fff;
  }
  
  /* #featured-radio:checked~main .featured {
    display: block;
  }
  
  #personal-radio:checked~main .personal {
    display: block;
  }
  
  #tech-radio:checked~main .tech {
    display: block;
  } */

  .block{
    display: block;
  }