/* table */
.dispatch_accounts_clearance .dispatch_accounts_clearance_table{
    width: 100% !important;
    font-size: .7rem;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table, .dispatch_accounts_clearance .dispatch_accounts_clearance_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .2rem;
    white-space: wrap;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(1){
    width: 2%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(2){
    width: 20%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(3){
    width: 10%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(4){
    width: 10%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(5){
    width: 10%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(6){
    width: 6%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(7){
    width: 6%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(8){
    width: 6%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(9){
    width: 6%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(10){
    width: 6%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(11){
    width: 6%;
}

.dispatch_accounts_clearance .dispatch_accounts_clearance_table thead tr td:nth-child(12){
    width: 13%;
}


/* btn clear */
.clear_for_dispatch_btn{
    font-size: 1.2rem;
    color: var(--col3);
    text-align: center;
    cursor: pointer;
}

.datepicker {
    width: inherit;
}