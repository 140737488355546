.risk_note_preview{
    position: absolute;
    z-index: -999999;
    top: 0;
    right: 100%;
}

.body_view_policy_header{
    display: flex;
}

.body_view_policy_header .controls{
    display: flex;
    grid-gap: .5rem;
}

.body_view_policy_header .controls .controls_items{
    display: flex;
    grid-gap: .3rem;
    align-items: center;
    font-size: .8rem;
}

.body_view_policy_header .controls .controls_items i{
    font-size: 1rem;
}

/* controls */
.view_policy_controls{
    position: relative;
}

.view_policy_controls .control_list{
    position: absolute;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.3s;
}

.newlist{
    position: absolute;
    transform: scaleY(1);
    margin-top: .8rem;
}

.view_policy_controls .newlist{
    width: 11rem;
    background-color: var(--col1);
}

.view_policy_controls .newlist .controls_items{
    padding: .5rem .8rem;
    cursor: pointer;
}

.view_policy_controls .newlist .controls_items:hover{
    background-color: var(--col7);
    color: var(--col1);
}