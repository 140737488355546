.select_insurer .select_insurer_list{
    list-style: none;
}

.select_insurer .select_insurer_list li{
    padding: .5rem;
    border: .1rem solid var(--col4);
    border-radius: .1rem;
    cursor: pointer;
    margin-bottom: .5rem;
}