.right_nav_content{
    padding: .5rem;
    color: var(--col1);
    font-size: .8rem;    
}

.lapse_date_picker_container{
    display: flex;
    flex-direction: column;
}

.lapse_date_picker_container input{
    width: 100%;
    height: 2rem;
    padding: .2rem .5rem;
    color: var(--col1);
}

.lapse_date_picker_container input:focus{
    outline: none;
}

.separator{
    width: 100%;
    height: .1rem;
    border-top: .1rem solid var(--col4);
}

.right_nav_content .right_nav_help{
    font-size: .6rem;
}

.right_nav_container .doc_upload{
    width: 100%;
}

.right_nav_container .doc_upload .input,.doc_upload .select{
    background-color: transparent !important;
    color: white !important;
    width: 100% !important;
    padding: .8rem .5rem !important;
}

.right_nav_container .doc_upload .select{
    background-color: var(--col5) !important;
}

.right_nav_container .doc_upload .file_upload_container{
    border:.1rem solid var(--col4);
    padding: .8rem .3rem;
}

/* mantin date picker  */
.mantine-right-nav-modal-date-picker .mantine-DatePickerInput-input {
    color: var(--col1) !important;
}

.mantine-right-nav-modal-date-picker .mantine-DatePickerInput-icon {
    color: var(--col1) !important;
}

.mantine-right-nav-modal-date-picker .mantine-DatePickerInput-error {
    color: var(--col1) !important;

}

.mantine-right-nav-modal-date-picker .mantine-DatePickerInput-label {
    color: var(--col1) !important;
}

/* focus */
.mantine-right-nav-modal-date-picker .mantine-DatePickerInput-input:focus {
    border: 1px solid var(--col4);
}

/* mantin year picker  */
.mantine-right-nav-modal-date-picker .mantine-YearPickerInput-input {
    color: var(--col1) !important;
    font-family: 'anonymousProRegular' !important;
    width: 100% !important;
}

.mantine-right-nav-modal-date-picker .mantine-YearPickerInput-icon {
    color: var(--col1) !important;
}

.mantine-right-nav-modal-date-picker .mantine-YearPickerInput-label {
    color: var(--col1) !important;
    font-family: 'anonymousProRegular' !important;
}

/* mantin month picker  */
.mantine-right-nav-modal-date-picker .mantine-MonthPickerInput-input {
    color: var(--col1) !important;
    font-family: 'anonymousProRegular' !important;
    width: 100% !important;
}

.mantine-right-nav-modal-date-picker .mantine-MonthPickerInput-icon {
    color: var(--col1) !important;
}

.mantine-right-nav-modal-date-picker .mantine-MonthPickerInput-label {
    color: var(--col1) !important;
    font-family: 'anonymousProRegular' !important;
}

/* hide scroll for right nav content */
.right_nav_container{
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.right_nav_container::-webkit-scrollbar {
    width: 0px;
    display: none;
}