/* table */
.body_post_new_receipt .search_policy_post_new_receipt_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.body_post_new_receipt .search_policy_post_new_receipt_table, .body_post_new_receipt .search_policy_post_new_receipt_table td{
    padding: .5rem .1rem;
}

.body_post_new_receipt .search_policy_post_new_receipt_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(1){
    width: 2%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(2){
    width: 13%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(3){
    width: 13%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(4){
    width: 13%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(5){
    width: 13%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(6){
    width: 7%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(7){
    width: 18%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(8){
    width: 7%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(9){
    width: 7%;
}

.body_post_new_receipt .search_policy_post_new_receipt_table thead tr td:nth-child(10){
    width: 7%;
}

/* ############################################################# */
/* allocations table */
.body_post_new_receipt .post_new_receipt_allocations_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.body_post_new_receipt .post_new_receipt_allocations_table, .body_post_new_receipt .post_new_receipt_allocations_table td{
    padding: .6rem .1rem;
}

.body_post_new_receipt .post_new_receipt_allocations_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(1){
    width: 2%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(2){
    width: 14%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(3){
    width: 14%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(4){
    width: 14%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(5){
    width: 16%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(6){
    width: 8%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(7){
    width: 8%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(8){
    width: 8%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(9){
    width: 8%;
}

.body_post_new_receipt .post_new_receipt_allocations_table thead tr td:nth-child(10){
    width: 8%;
}