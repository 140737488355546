.client_portfolio .client_search_container input{
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem .2rem 1.2rem;
    font-size: .9rem;
}

.client_portfolio .client_search_container input:focus{
    outline: none;
    border-bottom: 0.1px solid var(--col4);
}

.client_search_container{
    display: flex;
    align-items: center;
}

.client_search_container i{
    position: absolute;
    z-index: 1;
    margin-left: auto;
}

/* table */
.client_portfolio .all_clients_table{
    width: 100%;
    font-size: .7rem;
}

.client_portfolio .all_clients_table, .client_portfolio .all_clients_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
    cursor: pointer;
}

.client_portfolio .all_clients_table thead tr td:nth-child(1){
    width: 2%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(2){
    width: 38%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(3){
    width: 10%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(4){
    width: 10%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(5){
    width: 10%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(6){
    width: 10%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(7){
    width: 10%;
}

.client_portfolio .all_clients_table thead tr td:nth-child(8){
    width: 10%;
}