/* table */
.sticker_copy_report .sticker_copy_report_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.sticker_copy_report .sticker_copy_report_table, .sticker_copy_report .sticker_copy_report_table td{
    padding: .5rem .1rem;
}

.sticker_copy_report .sticker_copy_report_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.sticker_copy_report .sticker_copy_report_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(1){
    width: 2%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(2){
    width: 27%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(3){
    width: 27%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(4){
    width: 12%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(5){
    width: 12%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(6){
    width: 12%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(7){
    width: 4%;
}

.sticker_copy_report .sticker_copy_report_table thead tr td:nth-child(8){
    width: 4%;
}
