.insurer_statement .insurer_statement_category .category_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.insurer_statement_category .insurer_statement_table{
    width: 100%;
    border: 1px solid black;
    font-size: .6rem;
}

.insurer_statement_category .insurer_statement_table, .insurer_statement_category .insurer_statement_table tr td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(1){
    width: 1%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(2){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(3){
    width: 9%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(4){
    width: 9%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(5){
    width: 9%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(6){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(7){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(8){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(9){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(10){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(11){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(12){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(13){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(14){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(15){
    width: 6%;
}

.insurer_statement_category .insurer_statement_table tr td:nth-child(16){
    width: 6%;
} 