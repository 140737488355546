@media print {

    .pending_list_grid_page_break {
        break-after: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

    #myGrid {
        /* width: 100%; */
        font-size: .1rem !important;
    }

    body {
        overflow: visible;
      }
}