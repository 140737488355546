/* table */
.lapsed_policies .lapsed_policies_table{
    width: 100%;
    font-size: .6rem;
}

.lapsed_policies .lapsed_policies_table, .lapsed_policies .lapsed_policies_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .1rem;
    cursor: pointer;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(1){
    width: 2%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(2){
    width: 13%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(3){
    width: 7%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(4){
    width: 13%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(5){
    width: 13%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(6){
    width: 13%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(7){
    width: 7%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(8){
    width: 7%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(9){
    width: 13%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(10){
    width: 7%;
}

.lapsed_policies .lapsed_policies_table thead tr td:nth-child(11){
    width: 5%;
}