/* table */
.client_statement_template_cummulative_table {
    width: 100%;
    font-size: .7rem;
}

.client_statement_template_cummulative_table,
.client_statement_template_cummulative_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .2rem;
}

.client_statement_template_cummulative_table thead tr td:nth-child(1) {
    width: 7%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(2) {
    width: 7%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(3) {
    width: 17%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(4) {
    width: 16%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(5) {
    width: 16%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(6) {
    width: 16%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(7) {
    width: 7%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(8) {
    width: 7%;
}

.client_statement_template_cummulative_table thead tr td:nth-child(9) {
    width: 7%;
}


/* ageing */
.client_statement_template_ageing_table {
    width: 100%;
    font-size: .7rem;
}

.client_statement_template_ageing_table,
.client_statement_template_ageing_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .2rem;
}

.client_statement_template_ageing_table thead tr td:nth-child(1) {
    width: 16%;
}

.client_statement_template_ageing_table thead tr td:nth-child(2) {
    width: 12%;
}

.client_statement_template_ageing_table thead tr td:nth-child(3) {
    width: 12%;
}

.client_statement_template_ageing_table thead tr td:nth-child(4) {
    width: 12%;
}

.client_statement_template_ageing_table thead tr td:nth-child(5) {
    width: 12%;
}

.client_statement_template_ageing_table thead tr td:nth-child(6) {
    width: 12%;
}

.client_statement_template_ageing_table thead tr td:nth-child(7) {
    width: 12%;
}

.client_statement_template_ageing_table thead tr td:nth-child(8) {
    width: 12%;
}

/* details */
.client_statement_template_stmt_details_table {
    width: 100%;
    font-size: .7rem;
}

.client_statement_template_stmt_details_table,
.client_statement_template_stmt_details_table td {
    padding: .3rem .2rem;
}

.client_statement_template_stmt_details_table thead tr td:nth-child(1) {
    width: 33.3%;
}

.client_statement_template_stmt_details_table thead tr td:nth-child(2) {
    width: 33.3%;
}

.client_statement_template_stmt_details_table thead tr td:nth-child(3) {
    width: 33.3%;
}

@media screen {
    .client_statement_template {
        display: none;
    }
}

@media print {

    .client_statement_template_page_break {
        /* display: block;
        position: relative; */
        page-break-before: always !important;   
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

    /* table */
    .client_statement_template_cummulative_table,
    .client_statement_template_ageing_table,
    .client_statement_template_stmt_details_table {
        width: 100%;
        font-size: .6rem !important;
    }

    .watermark_logo {
        position: fixed;
        top: 50vh;
        z-index: 9;
        width: 60vw;
        page-break-after: always;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: .2;
    }

}