.client_statement .client_details_category {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.client_statement .client_controls {
    display: flex;
}

/* statement */
.client_statement_category .category_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CS_table table {
    width: 100%;
    border: 1px solid black;
    font-size: .6rem;
}

.CS_table table,
.CS_table table tr td {
    border: 1px solid black;
    border-collapse: collapse;
    /* padding: .5rem .2rem; */
    padding: .1rem .2rem;
}

.CS_table table thead tr td:nth-child(1) {
    width: 12%;
}

.CS_table table thead tr td:nth-child(2) {
    width: 11%;
}

.CS_table table thead tr td:nth-child(3) {
    width: 11%;
}

.CS_table table thead tr td:nth-child(4) {
    width: 20%;
}

.CS_table table thead tr td:nth-child(5) {
    width: 14%;
}

.CS_table table thead tr td:nth-child(6) {
    width: 9%;
}

.CS_table table thead tr td:nth-child(7) {
    width: 9%;
}

.CS_table table thead tr td:nth-child(8) {
    width: 9%;
}

.CS_table table thead tr td:nth-child(9) {
    width: 5%;
}

/* dates */
.cs_date_container {
    display: flex;
    width: inherit;
}

.cs_date_view {
    width: 90%;
}

.cs_date_selector {
    width: 10%;
}

.cs_date_selector i {
    position: absolute;
}

.cs_date_selector input {
    position: relative;
    z-index: 1;
    width: 1rem;
}

/* ageing */
.ageing_report table {
    width: 100%;
    border: 1px solid black;
}

.ageing_report table,
.ageing_report table tr td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .1rem .2rem;
    page-break-inside: avoid;
}

.ageing_report table thead tr td:nth-child(1) {
    width: 12%;
}

.ageing_report table thead tr td:nth-child(2) {
    width: 12%;
}

.ageing_report table thead tr td:nth-child(3) {
    width: 12%;
}

.ageing_report table thead tr td:nth-child(4) {
    width: 12%;
}

.ageing_report table thead tr td:nth-child(5) {
    width: 13%;
}

.ageing_report table thead tr td:nth-child(6) {
    width: 13%;
}

.ageing_report table thead tr td:nth-child(7) {
    width: 13%;
}

.ageing_report table thead tr td:nth-child(8) {
    width: 13%;
}

/* CS_details_narration */

.CS_details_narration thead {
    visibility: collapse;
}


/* printable statement */
.CS_content {
    /* padding: 2rem;
    width: 60rem; */
}

.CS_printable p {
    font-size: .6rem !important;
}

.CS_content,
.CS_content table,
.CS_content .company_info {
    font-size: .6rem !important;
}

.customer_statement_preview .logo_container {
    height: 8rem;
    display: flex;
    justify-content: end;
    align-items: center;
}

.customer_statement_preview .logo_container img {
    height: 8rem;
    width: 8rem;
}

/* title info */
.CS_title_info_header {
    text-align: center;
    font-size: .6rem;
}

.company_info {
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .6rem;
}

.company_info {
    border-top: 1px solid black;
}


/* client details header */
.CS_Document_Header {
    width: 100% !important;
    padding: .2rem 0 !important;
}

.CS_Document_Header thead tr td:nth-child(1) {
    width: 15% !important;
}

.CS_Document_Header thead tr td:nth-child(2) {
    width: 35% !important;
}

.CS_Document_Header thead tr td:nth-child(3) {
    width: 15% !important;
}

.CS_Document_Header thead tr td:nth-child(4) {
    width: 35% !important;
}



/* print */
.footer {
    text-align: center;
    width: 100%;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
    }

    /* p {
        font-size: .7rem;
    } */
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .footer {
        position: fixed;
        bottom: 0;
    }
}

/* @page {
    size: A4;
    margin: 7mm !important;
} */

.stmt_date_view {
    text-align: right;
}

.CS_printable {
    width: 100%;
}

.CS_table {
    width: 100%;
}