/* Printable Payroll Styling */
.universal {
    font-size: .8rem;
    width: 70%;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.companyLogo {
    align-content: flex-end;
    justify-self: flex-end;
    width: 10rem;
    padding-bottom: .8rem;
}

.tablesContainer {
    border-collapse: collapse;
    border: none;
    padding: 0;
}

.tablesDataTitle {
    border: none;
    padding: 0;
    width: 50%;
}

.tablesDataContent {
    border: none;
    padding: 0;
    text-align: right;
    width: 50%;
}

.tablesDataTitle p, .tablesDataContent p {
    font-weight: 900;
}

.payrollTitleContainer {
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.payrollTitle {
    text-align: center;
    text-decoration: underline;
    font-weight: 900;
}

.additionalInfo {
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.payrollValuesContainer {
    border-collapse: collapse;
    border-width: 0.5px;
    padding: 0;  
    width: 100%;
}

.payrollDataTitle {
    border-width: 0.5px;
    padding: 0;
    padding-left: .2rem;
    width: 50%;
}

.payrollDataContent {
    border-width: 0.5px;
    padding: 0;
    padding-right: .2rem;
    text-align: right;
}

.signatureContainer {
    border-collapse: collapse;
    border: none;
    table-layout: fixed;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.signatureDataTitle {
    border: none;
    padding: .1rem;
}

.signatureDataContent {
    border: none;
    border-bottom: 0.01px solid black;
    text-align: center;
    padding: .1rem;
}

.signatureDataTitle p, .signatureDataContent p {
    font-weight: 900;
}

.signatureSpacer {
    padding-top: 1.5rem;
}

