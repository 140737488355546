.risk_note_container {
    /* padding: 2rem; */
    font-size: .7rem;
}

.footer {
    text-align: center;
    width: 100%;
    
  }

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html,
    body {
        height: initial !important;
        overflow: initial !important;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .footer {
        position: fixed;
        bottom: 0;
      }
}

@page {
    size: A4;
    margin: 20mm;
  }

.risk_note_container .logo_container {
    height: 12rem;
    display: flex;
    justify-content: end;
    align-items: center;
}

.risk_note_container .logo_container img {
    height: 10rem;
    width: 10rem;
}

.risk_note_container .title {
    text-align: center;
}

.risk_note_container .risk_note_number {
    text-align: right;
}

.risk_note_container .main_table {
    width: 100%;
    border: 1px solid black;
}

.risk_note_container .main_table,
.risk_note_container .main_table tbody tr td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem;
}

.risk_note_container .main_table thead {
    visibility: collapse;
}

.risk_note_container .main_table thead td:nth-child(1) {
    width: 20%;
}

.risk_note_container .main_table thead td:nth-child(2) {
    width: 80%;
}

/* sub table */
.risk_note_container .sub_table {
    width: 100%;
    border: 1px solid black;
}

.risk_note_container .sub_table,
.risk_note_container .sub_table tbody tr td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem;
}

.risk_note_container .sub_table thead {
    visibility: visible;
}

/* particulars of the vehicle */
.risk_note_container .particularsOfTheVehicle thead td:nth-child(1) {
    width: 25%;
}

.risk_note_container .particularsOfTheVehicle thead td:nth-child(2) {
    width: 25%;
}

.risk_note_container .particularsOfTheVehicle thead td:nth-child(3) {
    width: 25%;
}

.risk_note_container .particularsOfTheVehicle thead td:nth-child(4) {
    width: 25%;
}

/* particular of the risk */
.risk_note_container .particularsOfTheRisk thead td:nth-child(1) {
    width: 50%;
}

.risk_note_container .particularsOfTheRisk thead td:nth-child(2) {
    width: 50%;
}

/* employee details */
.risk_note_container .employeeDetails thead td:nth-child(1) {
    width: 33.3%;
}

.risk_note_container .employeeDetails thead td:nth-child(2) {
    width: 33.3%;
}

.risk_note_container .employeeDetails thead td:nth-child(3) {
    width: 33.3%;
}

/* description */
.risk_note_container .description thead td:nth-child(1) {
    width: 50%;
}

.risk_note_container .description thead td:nth-child(2) {
    width: 50%;
}

/* deductibles */
.risk_note_container .deductibles thead td:nth-child(1) {
    width: 100%;
}

.risk_note_container .deductibles thead {
    visibility: collapse;
}

/* clauses */
.risk_note_container .clauses thead td:nth-child(1) {
    width: 100%;
}

.risk_note_container .clauses thead {
    visibility: collapse;
}

/* premium */
.risk_note_container .premium thead {
    visibility: collapse;
}

.risk_note_container .premium thead td:nth-child(1) {
    width: 25%;
}

.risk_note_container .premium thead td:nth-child(2) {
    width: 25%;
}

.risk_note_container .premium thead td:nth-child(3) {
    width: 25%;
}

.risk_note_container .premium thead td:nth-child(4) {
    width: 25%;
}

/* summary */
.risk_note_container .summary thead {
    visibility: collapse;
}

.risk_note_container .summary thead td:nth-child(1) {
    width: 60%;
}

.risk_note_container .summary thead td:nth-child(2) {
    width: 40%;
}

.risk_note_p_20 {
    padding-left: 1.3rem !important;
}

/* client details */
.risk_note_container .client_details thead {
    visibility: collapse;
}

.risk_note_container .client_details thead td:nth-child(1) {
    width: 30%;
}

.risk_note_container .client_details thead td:nth-child(2) {
    width: 70%;
}