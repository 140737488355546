.customHeaderMenuButton {
    float: left;
    margin: 0 0 0 3px;
  }
  
  .customHeaderLabel {
    float: left;
    margin: 0 0 0 3px;
  }
  
  .customSortDownLabel {
    float: left;
    margin: 0 0 0 3px;
  }
  
  .customSortUpLabel {
    float: left;
    margin: 0;
  }
  
  .customSortRemoveLabel {
    float: left;
    margin: 0 0 0 3px;
    font-size: 11px;
  }
  
  .active {
    color: cornflowerblue;
  }

  .ag-theme-alpine {
    --ag-font-size: 10px;    
}

  