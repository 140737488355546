.menu{
    color: var(--col1);
}

.menu_header{
    font-size: 1rem;
    padding: .7rem .4rem .2rem .4rem;
}

.sub_menu{
    display: flex;
    flex-direction: row;
    column-gap: .4rem;
    align-items: center;
    padding: 0 .4rem;
    height: 2.4rem;
    font-size: .9rem;
}

.sub_sub_menu div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.8rem;
    height: 1.7rem;
    cursor: pointer;
    font-size: .8rem;
}

.sub_menu_clickable{
    cursor: pointer;
}