.receipts_portfolio .receipt_portfolio_search_container input{
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem .2rem 1.2rem;
    font-size: .9rem;
}

.receipts_portfolio .receipt_portfolio_search_container input:focus{
    outline: none;
    border-bottom: 0.1px solid var(--col4);
}

.receipts_portfolio_search_container{
    display: flex;
    align-items: center;
}

.receipt_portfolio_search_container i{
    position: absolute;
    z-index: 1;
    margin-left: auto;
}

/* table */
.receipts_portfolio .all_receipts_table{
    width: 100%;
    font-size: .7rem;
}

.receipts_portfolio .all_receipts_table, .receipts_portfolio .all_receipts_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
    cursor: pointer;
}

.receipts_portfolio .all_receipts_table thead tr td:nth-child(1){
    width: 1%;
}

.receipts_portfolio .all_receipts_table thead tr td:nth-child(2){
    width: 15%;
}

.receipts_portfolio .all_receipts_table thead tr td:nth-child(3){
    width: 15%;
}

.receipts_portfolio .all_receipts_table thead tr td:nth-child(4){
    width: 54%;
}

.receipts_portfolio .all_receipts_table thead tr td:nth-child(5){
    width: 15%;
}
