.main_nav_final{
    height: 100%;
}

.main_nav_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
}
