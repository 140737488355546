.watermark {
    position: absolute;

    opacity: .2;

    justify-content: center;
    align-self: center;
}

.simia_receipt_template {
    min-height: 900px;
    display: flex;
    justify-content: center;

}

@media print {
    .f_8 {
        font-size: .8rem !important;
    }

    .f_7 {
        font-size: .7rem !important;
    }

    .f_10 {
        font-size: 1rem !important;
    }

    .footerr {
        width: 100%;
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: center;
    }


    /* simia receipt */
    .simia_receipt_template_content {
        width: 100%;
        font-size: .6rem;
    }

    /* section 1 */
    .simia_receipt_template_table_section_one {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    /* section 2 */
    .simia_receipt_template_table_section_two {
        width: 100%;
    }

    .simia_receipt_template_table_section_two,
    .simia_receipt_template_table_section_two td {
        padding: .5rem .3rem;
        border: none;
    }

    .simia_receipt_template_table_section_two thead tr td {
        vertical-align: top;
    }

    .simia_receipt_template_table_section_two thead tr td:nth-child(1) {
        width: 35%;
    }

    .simia_receipt_template_table_section_two thead tr td:nth-child(2) {
        width: 40%;
    }

    .simia_receipt_template_table_section_two thead tr td:nth-child(3) {
        width: 25%;
    }

    /* section 3 */
    .simia_receipt_template_table_section_three {
        width: 100%;
    }

    .simia_receipt_template_table_section_three,
    .simia_receipt_template_table_section_three td {
        padding: .5rem .3rem;
    }

    .simia_receipt_template_table_section_three tbody tr:nth-child(even) {
        background-color: #ced0ee;
    }

    .simia_receipt_template_table_section_three thead tr:nth-child(odd) {
        background-color: #cacdf1;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(1) {
        width: 22%;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(2) {
        width: 22%;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(3) {
        width: 22%;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(4) {
        width: 8%;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(5) {
        width: 8%;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(6) {
        width: 8%;
    }

    .simia_receipt_template_table_section_three thead tr td:nth-child(7) {
        width: 10%;
    }




    /* section 4 */
    .simia_receipt_template_table_section_four {
        width: 40%;
        margin-left: auto;
    }

    .simia_receipt_template_table_section_four,
    .simia_receipt_template_table_section_four td {
        padding: .7rem .5rem;
    }

    .simia_receipt_template_table_section_four tbody tr:nth-child(even) {
        background-color: #ced0ee;
    }

    .simia_receipt_template_table_section_four thead tr:nth-child(odd) {
        background-color: #cacdf1;
    }

    .simia_receipt_template_table_section_four thead tr td:nth-child(1) {
        width: 50%;
    }

    .simia_receipt_template_table_section_four thead tr td:nth-child(2) {
        width: 50%;
    }

    .simia_receipt_template_table_section_four thead tr td:nth-child(2) {
        text-align: right;
    }

}