.body_input_styles .input_group{
    position: relative;
}

.body_input_styles .input {
    padding: 1.1rem .5rem !important;    
    border-radius: 2px !important;
    outline: none !important;
    color: #444444 !important;
    border: 1px solid var(--col4) !important;
}

.body_input_styles .placeholder{
    position: absolute;
    top: 10px;
    left: 8px;
    font-size: 14px;
    padding: 1px 5px;
    transition: 0.3s;
    pointer-events: none;
}

.body_input_styles .input:focus + .placeholder,
.body_input_styles .input:not(:placeholder-shown)
+ .placeholder{
    top: -10px;
    background-color: var(--col4);
}

/* select */
.body_input_styles .select{
    height: auto !important;
    padding: .6rem .5rem !important;    
    border-radius: 2px !important;
    outline: none !important;
    color: #444444 !important;
    border: 1px solid var(--col4) !important;
}

.body_input_styles .select:focus + .placeholder,
.select:not(:placeholder-shown)
+ .placeholder{
    top: -9px;
    background-color: var(--col4);
}

/* text area */
.body_input_styles .textarea{
    height: auto !important;
    padding: .9rem .5rem !important;    
    border-radius: 2px !important;
    outline: none !important;
    color: #444444 !important;
    border: 1px solid var(--col4) !important;
}

.body_input_styles .textarea:focus + .placeholder,
.textarea:not(:placeholder-shown)
+ .placeholder{
    top: -10px;
    background-color: var(--col4);
}

/* search */
.body_input_styles .search_wrapper .input_group_search {
    position: relative;
}

.body_input_styles .search_wrapper .input_group_search .input_search {
    width: 100%;
    padding: .9rem .5rem !important;
    border-radius: 2px !important;
    outline: none !important;
    color: #444444 !important;
    border: 1px solid var(--col4) !important;
    border-radius: .2rem !important;
}

.body_input_styles .search_wrapper .input_group_search .placeholder {
    position: absolute;
    top: 12px;
    left: 8px;
    font-size: 14px;
    padding: 3px 5px;
    transition: 0.3s;
    pointer-events: none;
}

.body_input_styles .search_wrapper .input_group_search .input_search:focus + .placeholder,
.body_input_styles .search_wrapper .input_group_search .input_search:not(:placeholder-shown) + .placeholder {
    top: -10px !important;
    background-color: var(--col4) !important;
}

.body_input_styles .search_wrapper span.search_icon {
    position: absolute;
    background: none;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    width: 23px;
    padding: 0;
    cursor: pointer;
}