.sidebar {
    list-style: none;
    overflow-y: scroll;
    height: 100vh;
    cursor: pointer;
    /* hide scroll */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.sidebar .sidebar_menu .item {
    width: 100%;
    overflow: hidden;
}

.sidebar .sidebar_menu .item .menu_btn {
    /* display: block; */
    color: var(--col1);
    /* position: relative; */
    padding: 1.1rem .8rem;
    transition: 0.3s;
    transition-property: color;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar .sidebar_menu .item .menu_btn:hover::before {
    content: '';
    position: absolute;
    background-color: var(--col6);
    width: 6px;
    height: 100%;
    left: 0;
}

.sidebar .sidebar_menu .item .menu_btn .drop_down {
    /* float: right; */
    font-size: 10px;
    margin-bottom: 5px;
    margin-left: auto;
}

.sidebar .sidebar_menu .item .sub_menu {
    background: var(--col6);
    overflow: hidden;
    max-height: 0;
    transition: 0.6s;
    transition-property: background, max-height;
    padding-left: 1.4rem;
}

.sidebar .sidebar_menu .item .sub_menu div {
    display: block;
    position: relative;
    color: #fff;
    white-space: nowrap;
    font-size: .8rem;
    padding: .9rem 0 .9rem .9rem;
    transition: 0.3s;
    transition-property: background;
}

.sidebar .sidebar_menu .item .sub_menu a:hover {
    /* background: #55B1F0; */
}

.sidebar .sidebar_menu .item .sub_menu a:not(last-child) {
    /* border-bottom: 1px solid #8FC5E9; */
}

.sidebar .sidebar_menu .item .sub_menu i {
    /* padding-right: 20px; */
    font-size: 10px;
}

.sidebar .sidebar_menu .item:target .sub_menu {
    max-height: 1000px;
}

.sidebar .sidebar_menu .item:target .menu_btn::before {
    content: '';
    position: absolute;
    background-color: var(--col6);
    width: 6px;
    height: 100%;
    left: 0;
}