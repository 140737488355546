/* table */
.renewed_policies .renewed_policies_report{
    width: 100%;
    font-size: .6rem;
}

.renewed_policies .renewed_policies_report, .renewed_policies .renewed_policies_report td{
    /* border: 1px solid black;
    border-collapse: collapse; */
    padding: .4rem .1rem;
}

.renewed_policies .renewed_policies_report tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.renewed_policies .renewed_policies_report thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(1){
    width: 2%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(2){
    width: 16%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(3){
    width: 13%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(4){
    width: 13%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(5){
    width: 13%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(6){
    width: 6%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(7){
    width: 6%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(8){
    width: 13%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(9){
    width: 6%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(10){
    width: 4%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(11){
    width: 4%;
}

.renewed_policies .renewed_policies_report thead tr td:nth-child(12){
    width: 4%;
}

/* end of table */
td .policy_list_doc_icon{
    cursor: pointer;
}

.unrenewed_policies .renewed_policies_report{
    cursor: pointer;
}

