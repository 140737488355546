.dashboard_container{
    display: grid;
    grid-template-columns: repeat(17,1fr);
    grid-template-rows: 4rem auto;
    width: 100%;
}

/* .icon-nav{
    grid-row: 1/-1;
    grid-column: 1/2;
    height: 100vh;
    border-right: 0.1px solid var(--col4);
    position: sticky;
    top: 0;
} */

.text-nav{
    grid-row: 1/-1;
    grid-column: 1/4;
    height: 100vh;
    position: sticky;
    top: 0;
}

.main-nav{
    /* grid-column: 5/15; */
    grid-column: 4/15;
    background-color: var(--col1);
    border-bottom: 0.1px solid var(--col4);
    height: 4rem !important;
}

.bodyy{
    /* grid-column: 5/15; */
    grid-column: 4/15;
    background-color: var(--col1);
}

.right-navv{
    grid-row: 1/-1;
    grid-column: 15/-1;
    height: 100vh;
    position: sticky;
    top: 0;
    /* z-index: 3; */
}

/* message */
