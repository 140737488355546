.system_logs_table{
    width: 100%;
    font-size: .7rem;
}

.system_logs_table, .system_logs_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
    cursor: pointer;
}

.system_logs_table thead tr td:nth-child(1){
    width: 2%;
}

.system_logs_table thead tr td:nth-child(2){
    width: 15%;
}

.system_logs_table thead tr td:nth-child(3){
    width: 43%;
}

.system_logs_table thead tr td:nth-child(4){
    width: 15%;
}

.system_logs_table thead tr td:nth-child(5){
    width: 15%;
}

