.cds .day, .cds .month{
    width: 2rem;
    height: 2rem;
    border: 0.1px solid var(--col5);
    padding: 0;
    font-size: .9rem;
    text-align: center;
}

.cds .day, .cds .month{
    outline: none;
}

.cds .year{
    width: 3rem;
    height: 2rem;
    border: 0.1px solid var(--col5);
    padding: 0;
    font-size: .9rem;
    text-align: center;
}

.cds .year{
    outline: none;
}