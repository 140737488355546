/* table */
.insurer_statement_final .insurer_statement_final_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.insurer_statement_final .insurer_statement_final_table, .insurer_statement_final .insurer_statement_final_table td{
    padding: .4rem .1rem;
}

.insurer_statement_final .insurer_statement_final_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.insurer_statement_final .insurer_statement_final_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(1){
    width: 2%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(2){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(3){
    width: 8%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(4){
    width: 8%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(5){
    width: 10%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(6){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(7){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(8){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(9){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(10){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(11){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(12){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(13){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(14){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(15){
    width: 6%;
}

.insurer_statement_final .insurer_statement_final_table thead tr td:nth-child(16){
    width: 6%;
}

.insurer_statement_final_totals td p{
    font-size: .55rem;
}

.insurer_statement_final .insurer_statement_final_table tbody tr td:nth-child(5){
    font-size: .53rem;
}