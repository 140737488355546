:root{
    --col1: #F9F8FB;
    --col2: #00010A;
    --col3: #2B1BED;
    --col4: #B1AFCF;
    --col5: #5354D7;
    --col6: #323556;
    --col7: rgb(214, 27, 114);
    --col8: #caa200;
}

.h_1{
    height: .1rem;
}

.h_2{
    height: .2rem;
}

.h_3{
    height: .3rem;
}

.h_4{
    height: .4rem;
}

.h_5{
    height: .5rem;
}

.h_8{
    height: .8rem;
}

.h_10{
    height: 1rem;
}

.h_15{
    height: 1.5rem;
}

.h_20{
    height: 2rem;
}

.h_30{
    height: 3rem;
}

.w_2{
    width: .2rem;
}

.w_5{
    width: .5rem;
}

.w_10{
    width: 1rem;
}

.w_18{
    width: 1.8rem;
}

.f_5{
    font-size: .5rem !important;
}

.f_6{
    font-size: .6rem !important;
}

.f_7{
    font-size: .7rem !important;
}

.f_8{
    font-size: .8rem !important;
}

.f_9{
    font-size: .9rem !important;
}

.f_10{
    font-size: 1rem !important;
}

.p-h_5{
    padding-left: .5rem;
    padding-right: .5rem;
}

/* firebase deploy --except functions
firebase deploy --only functions 
http://localhost:5000/simia-9e230/us-central1/addmessage/
www.dmvic.com	https://www.dmvic.com/	motor.simiainsurance@gmail.com	Leonberg2504

*/