/* table */
.total_commission .total_commission_table{
    width: 100%;
    font-size: .6rem;
}

.total_commission .total_commission_table, .total_commission .total_commission_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .4rem .1rem;
}

.total_commission .total_commission_table thead tr td:nth-child(1){
    width: 2%;
}