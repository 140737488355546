.body_section {
    padding: .5rem;
}

.body_section_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--col1);
    height: 4.5rem;
    position: sticky;
    top: 0;
    padding: .5rem;
    box-shadow: rgba(87, 87, 87, 0.35) 0px 5px 15px;
    z-index: 5;
}

.body_section_header .left_content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.category {
    padding: 1rem .5rem;
    border-radius: .3rem;
    box-shadow: rgba(87, 87, 87, 0.35) 0px 5px 15px;
}

.category_content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
}

.category_header {
    font-size: .9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category_content_one {
    grid-column: 1/2;
    padding: 1rem .5rem;
}

.category_content_two {
    grid-column: 2/-1;
    padding: 1rem .5rem;
}

.category_content_three {
    grid-column: 1/-1;
    padding: 1rem .5rem;
}

.category_content input,
.category_content select,
.category_content textarea {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem;
    font-size: .9rem;
}

.category_content input:focus,
.category_content textarea:focus,
.category_content select:focus {
    outline: none;
    border-bottom: 0.1px solid var(--col4);
}

/* date picker */
.date_picker_container {
    display: flex;
    flex-direction: column;
    font-size: .9rem;
}

.date_picker_container .date_container {
    display: flex;
    flex-direction: column;
}

.date_picker_container .date_container_vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
}


/* display controls on category header */
.category_header .client_controls {
    display: flex;
}

/* right nav section filter*/
.right_nav_filter input,
.right_nav_filter select,
.right_nav_filter textarea {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem;
    font-size: .9rem;
    color: white;
}

.right_nav_filter input:focus,
.right_nav_filter textarea:focus,
.right_nav_filter select:focus {
    outline: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: var(--col5);
}

.right_nav_filter p {
    font-size: .9rem;
}

/* risk note */
.risk_note_preview {
    position: absolute;
    z-index: -999999;
    top: 0;
    right: 100%;
}

/* body section header controls */
.body_section_header .page_general_controls {
    display: flex;
}

/* policy search input */
.category_header.policy_search_container input {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem .2rem 1.2rem;
    font-size: .9rem;
}

.category_header.policy_search_container input:focus {
    outline: none;
    border-bottom: 0.1px solid var(--col4);
}

.category_header.policy_search_container {
    display: flex;
    align-items: center;
}

.category_header.policy_search_container i {
    position: absolute;
    z-index: 1;
    margin-left: auto;
}

/* responsive table */
.responsive_table {
    overflow-x: auto;

}

/* scroll bar */
/* body::-webkit-scrollbar{
    width: 20px;
    height: 20px;
}

body::-webkit-scrollbar-thumb{
    background: linear-gradient(
        to bottom, #ff850a, #ff3474
    );
    border-radius: 50px;
}

body::-webkit-scrollbar-track{
    background: #474747;
} */

/* header right side controls */
.body_section_header .right_side_controls {
    display: flex;
}


/* right click menu */
.u_r_right_click_menu {
    display: flex;
    flex-direction: column;
    background-color: var(--col4);
    cursor: pointer;
    font-size: .8rem;
}

.u_r_right_click_menu span {
    padding: .7rem 1rem .7rem 1rem;
}

.u_r_right_click_menu span:hover {
    background-color: var(--col7);
    color: var(--col1);
}

.u_r_right_click_menu div {
    background-color: rgb(143, 143, 143) !important;
    border-bottom: .1rem solid var(--col5);
}

/* show and hide */
.visible_component {
    visibility: visible;
}

.hidden_component {
    visibility: collapse;
}

/* print */



@media screen {
    .print_only_component {
        display: none;
    }

    .universal_company_footer {
        display: none;
    }
}

@media print {
    .print_only_component {
        display: block;
        font-size: .7rem;
    }

    .universal_company_footer {
        bottom: 0;
        display: flex;
        justify-content: center;
    }
}

/* mantine date picker */
.mantine_main_content_date_picker .mantine-DatePickerInput-label {
    font-family: 'anonymousProRegular';
}

/* .mantine_main_content_table_date_picker .mantine-DateInput-input {
    border: none;
    font-size: .1rem !important;
    font-family: 'anonymousProRegular';
} */

.mantine_main_content_table_date_picker .mantine-DatePickerInput-input {
    border: none !important;
    font-size: .7rem !important;
    font-family: 'anonymousProRegular' !important;
    width: 100% !important;
}

/* mantine  */
.email_client_statement_step_two .mantine-MultiSelect-input{
    border: none !important;
}

/* payrole statutory grid */
.payrole_statutory_dudection_title {
    background-color: sandybrown !important;
  }