/* table */
.pending_list_final .pending_list_final_table {
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.pending_list_final .pending_list_final_table,
.pending_list_final .pending_list_final_table td {
    padding: .5rem .1rem;
}

.pending_list_final .pending_list_final_table tbody tr:nth-child(even) {
    background-color: #ced0ee;
}

.pending_list_final .pending_list_final_table thead tr:nth-child(odd) {
    background-color: #cacdf1;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(1) {
    width: 2%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(2) {
    width: 13%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(3) {
    width: 5%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(4) {
    width: 10%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(5) {
    width: 10%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(6) {
    width: 10%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(7) {
    width: 5%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(8) {
    width: 5%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(9) {
    width: 10%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(10) {
    width: 5%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(11) {
    width: 5%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(12) {
    width: 4%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(13) {
    width: 4%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(14) {
    width: 4%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(15) {
    width: 4%;
}

.pending_list_final .pending_list_final_table thead tr td:nth-child(16) {
    width: 4%;
}

/* print */

@media screen {
    .pending_list_final_print_only_component {
        display: none;
    }
}

@media print {
    .pending_list_final_print_only_component {
        /* position: fixed; */
        /* bottom: 0; */
        display: block;
        font-size: .6rem;
    }
}