/* table */
.credit_note_allocations .credit_note_allocations_table{
    width: 100%;
    font-size: .6rem;
}

.credit_note_allocations .credit_note_allocations_table, .credit_note_allocations .credit_note_allocations_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .1rem;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(1){
    width: 2%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(2){
    width: 15%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(3){
    width: 12%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(4){
    width: 12%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(5){
    width: 12%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(6){
    width: 5%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(7){
    width: 5%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(8){
    width: 5%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(9){
    width: 12%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(10){
    width: 5%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(11){
    width: 12%;
}

.credit_note_allocations .credit_note_allocations_table thead tr td:nth-child(13){
    width: 3%;
}