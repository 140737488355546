.email_client_statement_printable_main_table {
    width: 100%;
    border: none !important;
    border-collapse: collapse;
}

.email_client_statement_printable_main_table_td {
    border: none !important;
    border-collapse: collapse;
}

.companyLogo {
    height: 9rem;
    width: 9rem;
    float: right;
    margin-bottom: 1rem;
}

.stmt_head_details {
    font-size: .6rem;
}

.stmt_head_details_table,
.stmt_head_details_table td {
    width: 100%;
    border:1px solid rgb(121, 121, 121);
    border-collapse: collapse;
}

.stmt_head_details_table thead td {
    padding: 0 .5rem;
    vertical-align: middle;
}

.stmt_head_details_table tbody td {
    padding: .4rem .5rem;
    vertical-align: top;
}

.stmt_head_details_table tbody tr td:nth-child(1) {
    width: 33.3%;
}

.stmt_head_details_table tbody tr td:nth-child(2) {
    width: 33.3%;
}

.stmt_head_details_table tbody tr td:nth-child(3) {
    width: 33.3%;
}

.stmt_section_header {
    background-color: rgb(22, 22, 83);
    color: #fafafa;
    line-height: 2.7rem !important;
    font-size: .8rem !important;
}

.stmt_section_headerr{
    background-color: rgb(22, 22, 83);
    color: #fafafa;
    line-height: 3.5rem !important;
    font-size: .8rem !important;
}

.stmt_head_policies_table thead tr:nth-child(2) td{
    line-height: 2.2rem !important;
}

/* table policies */
.stmt_head_policies_table td {
    width: 100%;
    border:1px solid rgb(121, 121, 121);
    border-collapse: collapse;
}

.stmt_head_policies_table thead td {
    padding: 0 .5rem;
    vertical-align: middle;
}

.stmt_head_policies_table tbody td {
    padding: .2rem .5rem;
    vertical-align: top;
}

.stmt_head_policies_table tr td:nth-child(1) {
    width: 12%;
}

.stmt_head_policies_table tr td:nth-child(2) {
    width: 12%;
}

.stmt_head_policies_table tr td:nth-child(3) {
    width: 12%;
}

.stmt_head_policies_table tr td:nth-child(4) {
    width: 18%;
}

.stmt_head_policies_table tr td:nth-child(5) {
    width: 14%;
}

.stmt_head_policies_table tr td:nth-child(6) {
    width: 9%;
}

.stmt_head_policies_table tr td:nth-child(7) {
    width: 5%;
}

.stmt_head_policies_table tr td:nth-child(8) {
    width: 9%;
}

.stmt_head_policies_table tr td:nth-child(9) {
    width: 5%;
}

/* aging */
.stmt_head_ageing_table,
.stmt_head_ageing_table td {
    width: 100%;
    border:1px solid rgb(121, 121, 121);
    border-collapse: collapse;

    page-break-inside: avoid;
}

.stmt_head_ageing_table thead td {
    padding: .6rem .3rem;
    vertical-align: middle;
}

.stmt_head_ageing_table tbody td {
    padding: .6rem .3rem;
}

.stmt_head_ageing_table tr td:nth-child(1) {
    width: 14%;
}

.stmt_head_ageing_table tr td:nth-child(2) {
    width: 14%;
}

.stmt_head_ageing_table tr td:nth-child(3) {
    width: 14%;
}

.stmt_head_ageing_table tr td:nth-child(4) {
    width: 14%;
}

.stmt_head_ageing_table tr td:nth-child(5) {
    width: 14%;
}

.stmt_head_ageing_table tr td:nth-child(6) {
    width: 14%;
}

.stmt_head_ageing_table tr td:nth-child(7) {
    width: 16%;
}

/* additional */
.stmt_head_additional_table,
.stmt_head_additional_table td {
    width: 100%;
    border:1px solid rgb(121, 121, 121);
    border-collapse: collapse;

    page-break-inside: avoid;
}

.stmt_head_additional_table thead td {
    padding: .5rem .5rem;
    vertical-align: middle;
}

.stmt_head_additional_table tbody td {
    padding: .2rem .5rem;
    vertical-align: top;
}

/* fleet */
.stmt_head_fleet_vehicles_table,
.stmt_head_fleet_vehicles_table td {
    width: 100%;
    border:1px solid rgb(121, 121, 121);
    border-collapse: collapse;
}

.stmt_head_fleet_vehicles_table thead td {
    padding: .6rem .3rem;
    vertical-align: middle;
}

.stmt_head_fleet_vehicles_table td {
    padding: .6rem .3rem;
}

.stmt_head_fleet_vehicles_table tr td:nth-child(1) {
    width: 12%;
}

.stmt_head_fleet_vehicles_table tr td:nth-child(2) {
    width: 12%;
}

.stmt_head_fleet_vehicles_table tr td:nth-child(3) {
    width: 12%;
}

.stmt_head_fleet_vehicles_table tr td:nth-child(4) {
    width: 64%;
}



.header,
.header-space,
.footer,
.footer_space {
    height: 70px;
}

.header {
    position: fixed;
    top: 0;
}

.footer {
    position: fixed;
    bottom: 0;
}

.email_client_statement_printable_footer {
    width: 100% !important;
    border: none;
    border-collapse: collapse !important;

    
}


.email_client_statement_printable_footer tr td:nth-child(1) {
    width: 33.3% !important;
}

.email_client_statement_printable_footer tr td:nth-child(2) {
    width:33.3% !important;
}

.email_client_statement_printable_footer tr td:nth-child(3) {
    width: 33.3% !important;
}

.MPESALogo{
    height: 2.2rem;
    width: 2.2rem;
}

.CIBLogo{
    height: 1.3rem;
    width: 4rem;
}

.GUARDIANLogo{
    height: 2.3rem;
    width: 8rem;
}

.email_client_statement_printable_footer_category{
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
}


.email_client_statement_printable_main_table tfoot{
    border: none !important;
        border-collapse: collapse;
}

.email_client_statement_printable_main_table tfoot td{
    border: none !important;
        border-collapse: collapse;
}


@media print {
    .email_client_statement_printable_main_table {
        width: 100%;
        border: none !important;
        border-collapse: collapse;
    }

    

    .email_client_statement_printable_footer {
        width: 100% !important;
        border: none;
        border-collapse: collapse !important;
    
    }
 
    
}