/* table */
.agent_statement .agent_statement_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.agent_statement .agent_statement_table, .agent_statement .agent_statement_table td{
    padding: .5rem .2rem;
}

.agent_statement .agent_statement_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.agent_statement .agent_statement_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.agent_statement .agent_statement_table thead tr td:nth-child(1){
    width: 2%;
}

.agent_statement .agent_statement_table thead tr td:nth-child(2){
    width: 10%;
}

.agent_statement .agent_statement_table thead tr td:nth-child(3){
    width: 20%;
}

.agent_statement .agent_statement_table thead tr td:nth-child(4){
    width: 48%;
}

.agent_statement .agent_statement_table thead tr td:nth-child(5){
    width: 10%;
}

.agent_statement .agent_statement_table thead tr td:nth-child(6){
    width: 10%;
}