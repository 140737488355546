.active_insurer_draggable{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    font-size: 24px;
    line-height: 1.5;
    cursor: move;
    z-index: 9999999;
}