.paginate ul{
    padding: .7rem;
    text-align: center;
}

.paginate li {
    display: inline-block;
    padding: .5rem;
    border: 1px solid var(--col2);
    margin: .1rem;
    cursor: pointer;
}