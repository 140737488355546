*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: anonymousProRegular;
  src: url(./Components/Assets/Fonts/raleway.regular.ttf);
}

@font-face {
  font-family: Raleway-SemiBold;
  src: url(./Components/Assets/Fonts/Raleway-Bold.ttf);
}

.bold{
  font-family: 'Raleway-SemiBold', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'anonymousProRegular', sans-serif;
  font-weight: bold;
}

p,a,span,input,button,select,textarea,li {
  font-family: 'anonymousProRegular';
}

html {
  box-sizing: border-box;
  background-color: var(--col5);
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.btn_pink {
  background-color: var(--col7);
  padding: .4rem 1rem;
  color: var(--col1);
  border-radius: .3rem;
  cursor: pointer;
}

.btn_yellow {
  /* background-color: var(--col8); */
  padding: .4rem 1rem;
  /* color: var(--col1); */
  border-radius: .3rem;
  cursor: pointer;
  border: 1px solid var(--col7);
  color: var(--col7);
}

.btn_back{
  padding: .3rem .15rem .15rem .15rem;
  font-size: 1.5rem;
  border-radius: .3rem;
  cursor: pointer;
  border: 0.1px solid var(--col5);
}

button {
  outline: none;
  border: none;
  font-size: .9rem;
}

.shadow{
  box-shadow: rgba(87, 87, 87, 0.35) 0px 5px 15px;
}

.message_info{
  position: absolute;
  padding: 1.5rem 2em;
  top: 50%;
  left: 35%;
  background-color: var(--col1);
  z-index: 101;
}

.hide{
  visibility: hidden;
}


.react-calendar *{
  color: black;
  z-index: 9999999 !important;
  font-family: anonymousProRegular;
  src: url(./Components/Assets/Fonts/raleway.regular.ttf);
  border-radius: .5rem;
  text-decoration: none !important;
  font-size: .7rem !important;
  
 
}

.react-calendar{
  border-radius: .5rem;
padding: 0.2rem !important;
margin: 0 !important;
}


/* p{
  font-size: .9rem;
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */