.footer {
    text-align: center;
    width: 100%;
    visibility: hidden;
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
    }
}

@media print {
    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .footer {
        position: fixed;
        bottom: 0;
    }
}

@page {
    size: A4;
    margin: 20mm;
}

@media print {
    table{
        width: 100%;
        border: 1px solid black;
        font-size: .6rem;
    }
    
    table, th, td{
        border: 1px solid black;
        border-collapse: collapse;
        padding: .5rem .1rem;
    }
    
}