/* table */
.conclusive_motor_report .conslusive_motor_report_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.conclusive_motor_report .conslusive_motor_report_table, .conclusive_motor_report .conslusive_motor_report_table td{
    padding: .5rem .1rem;
}

.conclusive_motor_report .conslusive_motor_report_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(1){
    width: 2%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(2){
    width: 23%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(3){
    width: 23%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(4){
    width: 12%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(5){
    width: 12%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(6){
    width: 12%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(7){
    width: 4%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(8){
    width: 4%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(9){
    width: 4%;
}

.conclusive_motor_report .conslusive_motor_report_table thead tr td:nth-child(10){
    width: 4%;
}