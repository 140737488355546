/* table */
.open_claims .open_claims_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.open_claims .open_claims_table, .open_claims .open_claims_table td{
    padding: .5rem .1rem;
}

.open_claims .open_claims_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.open_claims .open_claims_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.open_claims .open_claims_table thead tr td:nth-child(1){
    width: 2%;
}

.open_claims .open_claims_table thead tr td:nth-child(2){
    width: 13%;
}

.open_claims .open_claims_table thead tr td:nth-child(3){
    width: 13%;
}

.open_claims .open_claims_table thead tr td:nth-child(4){
    width: 11%;
}

.open_claims .open_claims_table thead tr td:nth-child(5){
    width: 11%;
}

.open_claims .open_claims_table thead tr td:nth-child(6){
    width: 7%;
}

.open_claims .open_claims_table thead tr td:nth-child(7){
    width: 11%;
}

.open_claims .open_claims_table thead tr td:nth-child(8){
    width: 32%;
}

