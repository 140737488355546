.policy_portfolio .policy_search_container input{
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent;
    padding: .2rem .5rem .2rem 1.2rem;
    font-size: .9rem;
}

.policy_portfolio .policy_search_container input:focus{
    outline: none;
    border-bottom: 0.1px solid var(--col4);
}

.policy_search_container{
    display: flex;
    align-items: center;
}

.policy_search_container i{
    position: absolute;
    z-index: 1;
    margin-left: auto;
}

/* table */
.policy_portfolio .all_policies_table{
    width: 100%;
    font-size: .7rem;
}

.policy_portfolio .all_policies_table, .policy_portfolio .all_policies_table td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: .5rem .1rem;
    cursor: pointer;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(1){
    width: 2%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(2){
    width: 16%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(3){
    width: 5%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(4){
    width: 16%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(5){
    width: 16%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(6){
    width: 13%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(7){
    width: 5%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(8){
    width: 5%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(9){
    width: 5%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(10){
    width: 5%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(11){
    width: 3%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(12){
    width: 3%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(13){
    width: 3%;
}

.policy_portfolio .all_policies_table thead tr td:nth-child(14){
    width: 3%;
}

/* doc icon */
.policy_list_doc_icon i{
    font-size: 1.2rem;
}