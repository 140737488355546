.payslip_content {
    width: 100%;
}

.payslip_main_content {
    width: 75%;
}

.companyLogo {
    height: 8rem;
    width: 8rem;
    float: right;
    margin-bottom: 1rem;
}

/* table */
.payslip_main_table {
    border: 1px solid rgb(71, 71, 71);
    border-collapse: collapse;
    border-width: thin;
    border-spacing: 0px;
    font-size: 8px;
}

.payslip_main_table td {
    border: 1px solid rgb(71, 71, 71);
    border-collapse: collapse;
    border-width: thin;
    border-spacing: 0px;
    padding: 1px;
}

/* .payslip_page_break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;

    page-break-before: always !important;   
    page-break-after: always !important;
    page-break-inside: avoid !important;
} */

@media all {
    .payslip_page_break {
        display: none;
    }
}

@media print {
    .payslip_page_break {
        /* margin-top: 1rem; */
        display: block;

        page-break-before: always !important;   
        page-break-after: always !important;
        page-break-inside: avoid !important;
    }

    .payslip_main_table {
        border: 1px solid rgb(71, 71, 71);
        border-collapse: collapse;
        border-width: thin;
        border-spacing: 0px;
        font-size: 8px;
    }
    
    .payslip_main_table td {
        border: 1px solid rgb(71, 71, 71);
        border-collapse: collapse;
        border-width: thin;
        border-spacing: 0px;
        padding: 1px;
    }

}

/* table */
.table_signature {
    border: none;
    border-collapse: collapse;
    border-width: thin;
    border-spacing: 0px;
    width: 100%;
    margin-top: 10px;
    font-size: 9px;
}

.table_signature td {
    border: none;
    border-collapse: collapse;
    border-width: thin;
    border-spacing: 0px;
    height: 25px;
    vertical-align: bottom !important;
    padding: 0;
}

.table_signature tr td:nth-child(2) {
    border-bottom: 1px solid rgb(71, 71, 71);
    border-collapse: collapse;
    border-width: thin;
    border-spacing: 0px;
}

.table_signature tbody tr td:nth-child(1) {
    width: 30%;
}

.table_signature tbody tr td:nth-child(2) {
    width: 70%;
}

@media print {
    .table_signature {
        border: none;
        border-collapse: collapse;
        border-width: thin;
        border-spacing: 0px;
        width: 100%;
        margin-top: 10px;
        font-size: 9px;
    }
    
    .table_signature td {
        border: none;
        border-collapse: collapse;
        border-width: thin;
        border-spacing: 0px;
        height: 25px;
        vertical-align: bottom !important;
        padding: 0;
    }
    
    .table_signature tr td:nth-child(2) {
        border-bottom: 1px solid rgb(71, 71, 71);
        border-collapse: collapse;
        border-width: thin;
        border-spacing: 0px;
    }
    
    .table_signature tbody tr td:nth-child(1) {
        width: 30%;
    }
    
    .table_signature tbody tr td:nth-child(2) {
        width: 70%;
    }
}