.sign_up_hero {
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, rgba(49, 24, 131, 0.9) 0%,
            rgba(72, 56, 149, 0.8) 100%), url("../Assets/img/header-bg.jpg") center no-repeat;
    background-size: cover;
    position: relative;
    color: #fafafa;
}

.sign_up_hero {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.sign_in_empty {
    filter: blur(8px);
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, rgba(49, 24, 131, 0.9) 0%,
            rgba(72, 56, 149, 0.8) 100%), url("../Assets/img/header-bg.jpg") center no-repeat;
    
}

.sign_in_content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.log_in_input_container input {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent !important;
    padding: .2rem .5rem;
    font-size: .9rem;
    color: var(--col4);
}

.log_in_input_container input:focus {
    outline: none;
    border-bottom: 0.1px solid var(--col4);
    background-color: transparent !important;
}

/* sub 1 */
.sign_up_sub {
    /* padding: 7rem 3rem; */
}

/* header */
.sign_up_header {
    font-size: 3rem;
}

.log_in_input_container.main_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}

.sign_up_button {
    width: 100%;
    display: block;
    text-align: center;
}

/* style link */
.redirectController {
    cursor: pointer;
    font-size: .8rem;
}