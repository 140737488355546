/* table */
.business_report .business_report_table{
    width: 100%;
    font-size: .6rem;
    cursor: pointer;
}

.business_report .business_report_table, .business_report .business_report_table td{
    padding: .5rem .1rem;
}

.business_report .business_report_table tbody tr:nth-child(even){
    background-color: #ced0ee;
}

.business_report .business_report_table thead tr:nth-child(odd){
    background-color: #cacdf1;
}

.business_report .business_report_table thead tr td:nth-child(1){
    width: 2%;
}

.business_report .business_report_table thead tr td:nth-child(2){
    width: 53%;
}

.business_report .business_report_table thead tr td:nth-child(3){
    width: 15%;
}

.business_report .business_report_table thead tr td:nth-child(4){
    width: 15%;
}

.business_report .business_report_table thead tr td:nth-child(5){
    width: 15%;
}