/* .insurer_account_overview {
    height: 13rem;
    padding: .5rem;
    border: .1rem solid var(--col4);
}

.insurer_active_account {
    background-color: var(--col5);
    color: var(--col1);
}

.insurer_account_overview_section_one .insurer_initials {
    font-size: 3rem;
}

.insurer_account_overview_section_one {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.insurer_account_overview_section_two .insurer_name {
    font-size: .9rem;
}

.insurer_account_overview_section_two .total_transactions {
    font-size: 2rem;
}

.insurer_account_overview_section_two .total_sepator {
    font-size: 1.5rem;
}

.insurer_account_overview_section_two .total_clients {
    font-size: 1.4rem;
} */

.main_screen_grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}


.stats_area_one {
    padding: .8rem;
    background-color: #E4A11B;
}

.stats_area_one_components {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: .5rem
}

.stats_area_one_components_icon {
    padding: .5rem;
    background-color: var(--col1);
}

.stats_area_two_a {
    font-size: 2rem;
}

.stats_area_two_b {
    background-color: #aa750c;
    padding: .2rem;
    font-size: .6rem;
    margin: 0 .5rem;
}

.stats_area_two_c {
    font-size: .7rem;
}



.main_screen_event {
    border: .1px solid var(--col4);
    padding: .5rem;
}

.main_screen_calender {
    border: .1px solid var(--col4);
    padding: .5rem;
}

.main_screen_event  {
    font-family: anonymousProRegular !important;
    src: url(./../Assets/Fonts/raleway.regular.ttf) !important;
}

/* Changes */

.title_container {
    display: flex;
    flex-direction: column;
    padding-bottom: .8rem;
    padding-top: .8rem;
}

.metrics_title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.insurance_title {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
}

.div_one {
    /* background-color: #73C1C6; */
    background-color: goldenrod;
    height: 50rem;
    width: 52rem;
}

.section_title {
    text-align: center;
    font-size: 1.2rem;
}

.section_title2 {
    text-align: center;
    font-size: 1rem;
}

.pending_align {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card_section {
    width: 25rem;
    background-color: transparent;
    padding: 1rem;
    margin-bottom: .5rem;
    margin-right: 1rem;
    border-style: solid;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pending_amounts {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-content: start;
    align-items: start;
}

.pending_values {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1rem;
}

.pending_box {
    width: 1.2rem;
    background-color: #677db7;
    border-radius: .3rem;
}

.pending_box_two {
    width: 1.2rem;
    background-color: #1c1c1c;
    border-radius: .3rem;
}

.pending_figures {
    margin-left: .3rem;
    font-size: 1rem;
}

.metrics_row {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
}

.unrenewed_amounts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 2rem;
}

.unrenewed_values {
    font-size: 1.5rem;
    font-weight: bold;
}

.businessVal_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}