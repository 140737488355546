.veh_input {
    outline: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;
}

.selected_insurance_classes {
    background-color: var(--col5);
    color: var(--col1);
    border: none !important;
    padding: 0 .4rem;
}

.create_expense_hide_component{
    display: none !important;
}