.create_policy_section .category_header{
    display: flex;
    justify-content: space-between;
}

.create_policy_section .client_details_controls {
    display: flex;
}

.client_details_controls .btn_pink,
.client_details_controls .btn_yellow {
    display: flex;
}

.date_container {
    display: flex;
    width: inherit;
    grid-gap: .5rem;
}

.date_view {
    width: 90%;
}

.date_selector {
    width: 10%;
}

.date_selector i {
    position: absolute;
}

.date_selector input {
    position: relative;
    z-index: 1;
    width: 1rem;
}

/* policy amounts */
.category_content_three .policy_amounts_table {
    width: 100%;
}

.policy_amounts_table,
.policy_amounts_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .policy_amounts_table thead td:nth-child(1) {
    width: 16%;
}

.category_content_three .policy_amounts_table thead td:nth-child(2) {
    width: 14%;
}

.category_content_three .policy_amounts_table thead td:nth-child(3) {
    width: 14%;
}

.category_content_three .policy_amounts_table thead td:nth-child(4) {
    width: 14%;
}

.category_content_three .policy_amounts_table thead td:nth-child(5) {
    width: 14%;
}

.category_content_three .policy_amounts_table thead td:nth-child(6) {
    width: 14%;
}

.category_content_three .policy_amounts_table thead td:nth-child(7) {
    width: 14%;
}

/* policy amount table 2 */
.category_content_three .policy_amounts_table_two {
    width: 100%;
}

.policy_amounts_table_two,
.policy_amounts_table_two td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(1) {
    width: 14%;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(2) {
    width: 14%;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(3) {
    width: 14%;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(4) {
    width: 14%;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(5) {
    width: 14%;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(6) {
    width: 15%;
}

.category_content_three .policy_amounts_table_two thead td:nth-child(7) {
    width: 15%;
}

/* employee's details */
.employees_details_controls {
    display: flex;
    font-size: 1.5rem;
    justify-content: center
}

.employees_details_controls i {
    cursor: pointer;
}

.category_content_three .employees_details_table {
    width: 100%;
}
.cat{color:rgb(214, 214, 214)}
.employees_details_table,
.employees_details_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .employees_details_table thead td:nth-child(1) {
    width: 60%;
}

.category_content_three .employees_details_table thead td:nth-child(2) {
    width: 10%;
}

.category_content_three .employees_details_table thead td:nth-child(3) {
    width: 20%;
}

.category_content_three .employees_details_table thead td:nth-child(4) {
    width: 10%;
}

/* description */
.property_description_controls {
    display: flex;
    font-size: 1.5rem;
    justify-content: center
}

.property_description_controls i {
    cursor: pointer;
}

.category_content_three .property_description_table {
    width: 100%;
}

.property_description_table,
.property_description_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .property_description_table thead td:nth-child(1) {
    width: 70%;
}

.category_content_three .property_description_table thead td:nth-child(2) {
    width: 20%;
}

.category_content_three .property_description_table thead td:nth-child(3) {
    width: 10%;
}

/* benefits */
.benefits_controls {
    display: flex;
    font-size: 1.5rem;
    justify-content: center
}

.benefits_controls i {
    cursor: pointer;
}

.category_content_three .benefits_table {
    width: 100%;
}

.benefits_table,
.benefits_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .benefits_table thead td:nth-child(1) {
    width: 30%;
}

.category_content_three .benefits_table thead td:nth-child(2) {
    width: 60%;
}

.category_content_three .benefits_table thead td:nth-child(3) {
    width: 10%;
}

/* deductibles */
.deductibles_controls {
    display: flex;
    font-size: 1.5rem;
    justify-content: center
}

.deductibles_controls i {
    cursor: pointer;
}

.category_content_three .deductibles_table {
    width: 100%;
}

.deductibles_table,
.deductibles_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .deductibles_table thead td:nth-child(1) {
    width: 90%;
}

.category_content_three .deductibles_table thead td:nth-child(2) {
    width: 20%;
}

.category_content_three .deductibles_table thead{
    visibility: collapse;
}


/* particulars of the vehicle*/
.category_content_three .vehicle_table {
    width: 100%;
}

.vehicle_table,
.vehicle_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .vehicle_table thead td {
    width: 10%;
}

/* clauses */
.clauses_controls {
    display: flex;
    font-size: 1.5rem;
    justify-content: center
}

.clauses_controls i {
    cursor: pointer;
}

.category_content_three .clauses_table {
    width: 100%;
}

.clauses_table,
.clauses_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
}

.category_content_three .clauses_table thead td:nth-child(1) {
    width: 90%;
}

.category_content_three .clauses_table thead td:nth-child(2) {
    width: 20%;
}

.category_content_three .clauses_table thead{
    visibility: collapse;
}

/* policy docs */
.policy_documents_table{
    width: 100%;
}

.policy_documents_table,
.policy_documents_table td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: .3rem .5rem;
    font-size: .8rem;
    cursor: pointer;
}

.policy_documents_table thead td:nth-child(1) {
    width: 23%;
}

.policy_documents_table thead td:nth-child(2) {
    width: 23%;
}

.policy_documents_table thead td:nth-child(3) {
    width: 23%;
}

.policy_documents_table thead td:nth-child(4) {
    width: 20%;
}

.policy_documents_table thead td:nth-child(5) {
    width: 11%;
}